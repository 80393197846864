import { inject, Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public isLoading = signal<boolean>(false);

  public setIsLoading(value: boolean) {
    this.isLoading.set(value);
  }
}
